import axios from "axios";
import { getCookie } from "../Tool/CookieUtils";


const BASE_URL = process.env.REACT_APP_LOCAL_HOST
const USER_URL = process.env.REACT_APP_URL_ALL_USER
const UPDATE_MYDATA_URL = process.env.REACT_APP_URL_UPDATE_MYDATA
const USER_PROFILE_URL = process.env.REACT_APP_URL_PROFILE
const USER_JOBDESK = process.env.REACT_APP_URL_CURRENT_JOBDESK

export const fetchAllUsers = async () => {
    const token = getCookie('token')
    try {
        const response = await axios.get(
          BASE_URL+USER_URL,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        return response.data;
      } catch (error) {
        console.error('Error fetch user:', error);
        throw error;
      }
  };

  export const updateUser = async (userID, userData) => {
    const token = getCookie('token'); 
    try {
      const response = await axios.put(
        `${BASE_URL + USER_URL}/${userID}`, 
        userData, 
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error updating user:', error);
      throw error;
    }
  };

  export const updateMyData = async (userID, userData) => {
    const token = getCookie('token'); 
    console.log(`${BASE_URL + UPDATE_MYDATA_URL}/${userID}`)
    try {
      const response = await axios.put(
        `${BASE_URL + USER_URL}/${userID}`, 
        userData, 
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error updating user:', error);
      throw error;
    }
  };

  export const myProfile = async () => {
    const token = getCookie('token'); 
    try {
      const response = await axios.get(
        `${BASE_URL + USER_PROFILE_URL}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error updating user:', error);
      throw error;
    }
  };


export const deleteUser = async (id) => {
    const token = getCookie('token'); 
    try {
      const response = await axios.delete(
        `${BASE_URL + USER_URL}/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error delete user:', error);
      throw error;
    }
  };
 


  export const registerUser = async (username,email, password, inisial) =>{
    try {
      const response = await axios.post(
        `${BASE_URL + USER_URL}/register-user`, {username, email, password, inisial}
      )
      return response;
      
    } catch (error) {
      console.error('Error register user:', error);
      throw error;
    }
  }

  export const adminRegisterUser = async (username,email, password, roleRegistration, inisial) =>{
    const token = getCookie('token'); 
    try {
      const response = await axios.post(
        `${BASE_URL + USER_URL}/register`, {username, email, password, role : roleRegistration, inisial}, {
          headers:{
            Authorization :`Bearer ${token}`
          }
        }
      )
      return response;
      
    } catch (error) {
      console.error('Error register user:', error);
      throw error;
    }
  }
//
  export const currentJobdesk = async () =>{
    const token = getCookie('token'); 
    try {
      const response = await axios.get(
        `${USER_JOBDESK}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error updating user:', error);
      throw error;
    }
  }