import React, { useEffect, useState } from "react";
import { useAuth } from "../Store/AuthContext";
import { useNavigate } from "react-router-dom";
import {
  FaUser,
  FaEnvelope,
  FaIdBadge,
  FaUserTag,
  FaUserEdit,
   
} from "react-icons/fa";
import { GiDesk } from "react-icons/gi";
//List https://react-icons.github.io/react-icons/
import { MdOutlineDashboard } from "react-icons/md";
import { SiNamecheap } from "react-icons/si";
import EditUserModal from "./EditUserModal";
import { myProfile } from "../service/UsersService";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import useDocumentTitle from "../hooks/useDocumentTitle";

const UserProfile = () => {
  const { isLoggedIn } = useAuth();
  const [profile, setProfile] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  useDocumentTitle("Profile")

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    } else {
      const fetchProfile = async () => {
        try {
          const data = await myProfile();
        
          setProfile(data.data.user);
        } catch (error) {
          console.error('Error fetching profile:', error);
        }
      };

      fetchProfile();
    }
  }, [isLoggedIn, navigate]);

  const handleSave = (updatedUser) => {
    setProfile(updatedUser); // Update the profile state with the new data
  };

  return (
    <div className={`flex items-center justify-center w-full ${isModalOpen ? "blur" : ""}`}>
 
      <div className={`max-w-md mx-auto mt-8 bg-white shadow-lg rounded-lg overflow-hidden `}>
        <div className="p-6">
          <div className="flex flex-col items-start">
            <h2 className="text-2xl font-semibold mb-6">User Profile</h2>
            <div className="flex items-center mb-4 w-full">
              <FaIdBadge className="text-gray-500 mr-3" />
              <div className="flex-1 flex items-center space-x-3">
                <span className="font-semibold">{profile ? "ID  :" : <Skeleton width={40} />}</span>
                <span>{profile ? ` ${profile._id}` : <Skeleton width={100} />}</span>
              </div>
            </div>
            <div className="flex items-center mb-4 w-full">
              <FaUser className="text-gray-500 mr-3" />
              <div className="flex-1 flex items-center space-x-3">
                <span className="font-semibold">{profile ? "Username:" : <Skeleton width={90} />}</span>
                <span>{profile ? ` ${profile.username}` : <Skeleton width={150} />}</span>
              </div>
            </div>
            <div className="flex items-center mb-4 w-full">
              <FaEnvelope className="text-gray-500 mr-3" />
              <div className="flex-1 flex items-center space-x-3">
                <span className="font-semibold">{profile ? "Email:" : <Skeleton width={50} />}</span>
                <span>{profile ? ` ${profile.email}` : <Skeleton width={200} />}</span>
              </div>
            </div>
            <div className="flex items-center mb-4 w-full">
              <SiNamecheap className="text-gray-500 mr-3" />
              <div className="flex-1 flex items-center space-x-3">
                <span className="font-semibold">{profile ? "Inisial:" : <Skeleton width={50} />}</span>
                <span>{profile ? ` ${profile.inisial}` : <Skeleton width={100} />}</span>
              </div>
            </div>
            <div className="flex items-center mb-4 w-full">
              <FaUserTag className="text-gray-500 mr-3" />
              <div className="flex-1 flex items-center space-x-3">
                <span className="font-semibold">{profile ? "Role:" : <Skeleton width={50} />}</span>
                <span>{profile ? ` ${profile.role}` : <Skeleton width={100} />}</span>
              </div>
            </div>
            <div className="flex items-center mb-4 w-full">
              <GiDesk className="text-gray-500 mr-3" />
              <div className="flex-1 flex items-center space-x-3">
                <span className="font-semibold">{profile ? "Jobdesk:" : <Skeleton width={50} />}</span>
                <span>{profile ? `${profile.jobdesk || '-'}` : <Skeleton width={100} />}</span>
              </div>
            </div>
            <div className="flex space-x-16 w-full justify-between">
              <button
                onClick={() => navigate("/")}
                className="bg-dark-primary flex items-center space-x-8 py-2 px-4 min-w-36 text-white mt-6 rounded-md hover:bg-dark-secondary transition duration-300"
              >
                <MdOutlineDashboard />
                <div>Dashboard</div>
              </button>
              <button
                onClick={() => setIsModalOpen(true)}
                className="bg-dark-primary flex items-center space-x-8 min-w-36 py-2 px-4 text-white mt-6 rounded-md hover:bg-dark-secondary transition duration-300"
              >
                <FaUserEdit className="text-white " /> <div>Edit</div>
              </button>
            </div>
          </div>
        </div>
        <EditUserModal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          currentUser={profile}
          onSave={handleSave}
        />
      </div>
    </div>
  );
};

export default UserProfile;
