import React, { useState, useEffect } from "react";
import {
  fetchTickets,
  fetchJobdeskOptions,
  createTicket,
  updateTicket,
  fetchTicketsByDate,
  deleteTicket,
  fetchTicketsByNomorTiket,
} from "../service/TicketService";
import { currentJobdesk } from "../service/UsersService";

const TicketManagement = () => {
  const [tickets, setTickets] = useState([]);
  const [formData, setFormData] = useState({
    jobdesk: "",
    pemilikTiket: "",
    nomorTiket: "",
    escalated: "",
  });
  const [editMode, setEditMode] = useState(false);
  const [ticketId, setTicketId] = useState(null);
  const [jobdeskOptions, setJobdeskOptions] = useState([]);
  const [currentJob, setCurrentJob] = useState("");

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false); // State untuk modal konfirmasi
  const [ticketToDelete, setTicketToDelete] = useState(null); // State untuk tiket yang akan dihapus

  // New pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRecording, setTotalRecording] = useState(0);
  const limit = 10; // Items per page
  const [selectedDate, setSelectedDate] = useState("");
  const [error, setError] = useState(null);

  //pencarian nomor tiket
  const [searchQuery, setSearchQuery] = useState("");

  // State untuk filter satu tanggal

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    const setDate = () => {
      try {
        const saveDate = localStorage.getItem("selectedDate");
        
        if (saveDate) {
          setSelectedDate(saveDate);
           
        } else {
          setSelectedDate(""); // Jika tidak ada tanggal di localStorage, biarkan kosong
        }
      } catch (error) {
        console.log(error);
      }
    };
    setDate();
  }, []); // Jangan gunakan [selectedDate], agar hanya dipanggil sekali saat komponen di-mount

  useEffect(() => {
    const getTickets = async () => {
      setIsLoading(true);
      setError(null);
      try {
        let response;
        if (selectedDate) {
          response = await fetchTicketsByDate(selectedDate, currentPage, limit);
        } else {
          response = await fetchTickets(currentPage, limit);
        }
         
        setTickets(response.tickets);
        setTotalPages(response.totalPages);
        setTotalRecording(response.totalTickets);
      } catch (error) {
        console.error("Error fetching tickets:", error.message);
        setError("Failed to fetch tickets. Please try again.");
      }
      setIsLoading(false);
    };
    getTickets();
  }, [currentPage, selectedDate]); // Rerun effect jika selectedDate atau currentPage berubah

  const handleDateChange = (e) => {
    const newDate = e.target.value;
    setSelectedDate(newDate);
    localStorage.setItem("selectedDate", newDate); // Update localStorage saat user merubah tanggal
    setCurrentPage(1); // Reset ke halaman pertama saat merubah tanggal
  };

  const clearDateFilter = () => {
    setSelectedDate("");
    localStorage.removeItem("selectedDate"); // Hapus tanggal dari localStorage
    setCurrentPage(1);
  };
  const setDateToday = () => {
    const today = formatDate(new Date()); // Format tanggal hari ini
    setSelectedDate(today);
    localStorage.setItem("selectedDate", today); // Simpan tanggal hari ini ke localStorage
    setCurrentPage(1); // Reset ke halaman pertama
  };

  useEffect(() => {
    const getCurrentJob = async () => {
      try {
        const savedJobdesk = localStorage.getItem("selectedJobdesk");

        const jobdeskOptions = await fetchJobdeskOptions();
        setJobdeskOptions(jobdeskOptions);

        if (savedJobdesk) {
          setFormData((prev) => ({ ...prev, jobdesk: savedJobdesk }));
          setCurrentJob(savedJobdesk);
        } else {
          const currentJobData = await currentJobdesk();

          if (currentJobData?.length > 0) {
            const currentJob = currentJobData[0].Jobdesk;
            setCurrentJob(currentJob);
            setFormData((prev) => ({ ...prev, jobdesk: currentJob }));
          } else {
            setCurrentJob("");
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };

    getCurrentJob();
  }, []);

  const handleJobdeskChange = (e) => {
    const selectedJobdesk = e.target.value;
    setFormData({ ...formData, jobdesk: selectedJobdesk });
    setCurrentJob(selectedJobdesk);
    localStorage.setItem("selectedJobdesk", selectedJobdesk);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editMode) {
        await updateTicket(ticketId, formData);
        setEditMode(false);
      } else {
        await createTicket(formData);
      }
      setFormData((prev) => ({
        jobdesk: prev.jobdesk,
        pemilikTiket: "",
        nomorTiket: "",
        escalated: "",
      }));
      // Ambil data tiket terbaru dengan pagination
      const updatedTickets = await fetchTickets(currentPage, limit);
      setTickets(updatedTickets.tickets);
      setTotalPages(updatedTickets.totalPages);
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteTicket(id);
      // Ambil data tiket terbaru dengan pagination
      const updatedTickets = await fetchTickets(currentPage, limit);
      setTickets(updatedTickets.tickets);
      setTotalPages(updatedTickets.totalPages);
      setShowDeleteConfirm(false); // Sembunyikan modal setelah berhasil menghapus
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleEdit = (ticket) => {
    setFormData({
      jobdesk: ticket.jobdesk,
      pemilikTiket: ticket.pemilikTiket,
      nomorTiket: ticket.nomorTiket,
      escalated: ticket.escalated,
    });
    setTicketId(ticket._id);
    setEditMode(true);
  };

  const confirmDelete = (ticket) => {
    setTicketToDelete(ticket); // Set tiket yang ingin dihapus
    setShowDeleteConfirm(true); // Tampilkan modal konfirmasi
  };

  const cancelDelete = () => {
    setTicketToDelete(null);
    setShowDeleteConfirm(false); // Tutup modal konfirmasi
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearch = async () => {
    setIsLoading(true);
    setError(null);

    try {
      let response;

      if (searchQuery) {
        response = await fetchTicketsByNomorTiket(searchQuery);
      } else {
        response = await fetchTickets(currentPage, limit);
      }

      setTickets(response.tickets);
      setTotalPages(response.totalPages);
      setTotalRecording(response.totalTickets);
    } catch (error) {
      console.error("Error fetching tickets:", error.message);
      setError("Gagal mencari tiket. Silakan coba lagi.");
    }

    setIsLoading(false);
  };

  const handleReset = () => {
    setSearchQuery("");
  };

  const handleEscalationChange = async (ticketId, newEscalatedState) => {
    const confirmation = window.confirm(
      "Apakah Anda yakin ingin mengubah status eskalasi tiket ini?"
    );

    if (confirmation) {
      try {
        // Update tiket dengan state baru dari checkbox
        const updatedFormData = { escalated: newEscalatedState };

        await updateTicket(ticketId, updatedFormData);

        // Update UI setelah sukses mengupdate
        setTickets((prevTickets) =>
          prevTickets.map((ticket) =>
            ticket._id === ticketId
              ? { ...ticket, escalated: newEscalatedState }
              : ticket
          )
        );

        alert("Status eskalasi berhasil diupdate!");
      } catch (error) {
        console.error("Error updating ticket:", error);
        alert("Gagal mengupdate status eskalasi tiket.");
      }
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Ticket Management</h1>
      <div className="flex flex-col lg:flex-row">
        <div className="w-full lg:w-1/3 lg:pr-4 mb-4 lg:mb-0">
          <h2 className="text-xl font-bold mb-4">
            {editMode ? "Edit Ticket" : "Create Ticket"}
          </h2>
          <form
            onSubmit={handleSubmit}
            className="bg-white p-4 shadow-md rounded-md"
          >
            <div className="mb-4">
              <label className="block text-sm font-medium">Jobdesk</label>
              <select
                value={currentJob || ""}
                onChange={handleJobdeskChange}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                required
              >
                <option value="">
                 Pilih Jobdesk
                </option>
                {jobdeskOptions.map((jobdesk) => (
                  <option key={jobdesk._id} value={jobdesk.jobdesk}>
                    {jobdesk.jobdesk}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium">Nomor Tiket</label>
              <input
                type="text"
                value={formData.nomorTiket}
                onChange={(e) =>
                  setFormData({ ...formData, nomorTiket: e.target.value })
                }
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium">Eskalasi</label>
              <input
                type="checkbox"
                checked={formData.escalated}
                onChange={(e) =>
                  setFormData({ ...formData, escalated: e.target.checked })
                }
              />
            </div>
            <button
              type="submit"
              className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600"
            >
              {editMode ? "Update Ticket" : "Create Ticket"}
            </button>
          </form>
        </div>

        <div className="w-full lg:w-2/3 lg:pl-4">
          <h2 className="text-xl font-bold mb-4">Tickets List</h2>
          <h3 className="text-md font-bold py-2">Total : {totalRecording}</h3>
          {/* Form Filter Date dan pencarian*/}
          <div className="flex justify-between space-x-2">
            <div className="w-full  lg:pr-4 mb-4 lg:mb-0">
              <div className="bg-white p-4 shadow-md rounded-md">
                <div className="mb-4">
                  <label className="block text-sm font-medium">
                    Filter Tanggal
                  </label>
                  <input
                    type="date"
                    value={selectedDate}
                    onChange={handleDateChange}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                  />
                </div>
                <div className=" space-y-2 flex flex-col w-full sm:justify-normal">
                  <button
                    onClick={clearDateFilter}
                    className="bg-gray-500 text-white py-2 px-4 rounded-md hover:bg-gray-600"
                  >
                    Reset
                  </button>
                  <button
                    onClick={setDateToday}
                    className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600"
                  >
                    Today
                  </button>
                </div>
              </div>
            </div>
            <div className="w-full lg:pr-4 mb-4 lg:mb-0">
              <div className="bg-white p-4 shadow-md rounded-md">
                <div className="mb-4">
                  <label className="block text-sm font-medium">
                    Cari Nomor Tiket
                  </label>
                  <input
                    type="text"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    placeholder="Masukkan nomor tiket..."
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                  />
                </div>
                <div className=" space-y-2 flex flex-col w-full sm:justify-normal">
                  <button
                    onClick={handleReset}
                    className="bg-gray-500 text-white py-2 px-4 rounded-md hover:bg-gray-600"
                  >
                    Reset
                  </button>
                  <button
                    onClick={handleSearch}
                    className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600"
                  >
                    Cari
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="my-7"></div>
          {error && <div className="text-red-500 mb-4">{error}</div>}
          {isLoading ? (
            <div className="text-center py-4">Loading...</div>
          ) : (
            <>
              {tickets.length === 0 ? (
                <div className="text-center py-4">No tickets found</div>
              ) : (
                <div className="overflow-x-auto">
                  <table className="min-w-full bg-white">
                    <thead className="bg-gray-50">
                      <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Nomor Tiket
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Jobdesk
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Tanggal
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Eskalasi
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {tickets.map((ticket) => (
                        <tr key={ticket._id}>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {ticket.nomorTiket}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {ticket.jobdesk}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {new Date(ticket.tanggal).toLocaleDateString()}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <input
                              type="checkbox"
                              onChange={() =>
                                handleEscalationChange(
                                  ticket._id,
                                  !ticket.escalated
                                )
                              } // Pass ticket ID and new escalation state
                              className="w-4 h-4 text-red-600 bg-gray-100 border-gray-300 rounded focus:ring-red-500"
                              checked={ticket.escalated}
                            />
                          </td>

                          <td className="px-6 py-4 flex items-center whitespace-nowrap text-right text-sm font-medium">
                            <button
                              onClick={() => handleEdit(ticket)}
                              className="text-indigo-600 hover:text-indigo-900 mr-2"
                            >
                              Edit
                            </button>
                            <button
                              onClick={() => confirmDelete(ticket)}
                              className="text-red-600 hover:text-red-900"
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}

              {/* Pagination controls */}
              {totalPages > 1 && (
                <div className="flex justify-center mt-4">
                  <nav
                    className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                    aria-label="Pagination"
                  >
                    <button
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                      className={`relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium ${
                        currentPage === 1
                          ? "text-gray-300 cursor-not-allowed"
                          : "text-gray-500 hover:bg-gray-50"
                      }`}
                    >
                      Previous
                    </button>

                    {[...Array(totalPages)].map((_, index) => (
                      <button
                        key={index + 1}
                        onClick={() => handlePageChange(index + 1)}
                        className={`relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium ${
                          currentPage === index + 1
                            ? "z-10 bg-indigo-50 border-indigo-500 text-indigo-600"
                            : "bg-white text-gray-500 hover:bg-gray-50"
                        }`}
                      >
                        {index + 1}
                      </button>
                    ))}

                    <button
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPages}
                      className={`relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium ${
                        currentPage === totalPages
                          ? "text-gray-300 cursor-not-allowed"
                          : "text-gray-500 hover:bg-gray-50"
                      }`}
                    >
                      Next
                    </button>
                  </nav>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {/* Modal Konfirmasi Hapus */}
      {showDeleteConfirm && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded-md shadow-md">
            <p className="text-xl font-bold mb-4">
              Are you sure you want to delete this ticket?
            </p>
            <div className="flex justify-end">
              <button
                onClick={() => handleDelete(ticketToDelete._id)} // Hapus tiket
                className="bg-red-500 text-white py-2 px-4 rounded-md hover:bg-red-600 mr-2"
              >
                Yes, Delete
              </button>
              <button
                onClick={cancelDelete} // Batalkan penghapusan
                className="bg-gray-500 text-white py-2 px-4 rounded-md hover:bg-gray-600"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TicketManagement;
