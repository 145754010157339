// src/services/ticketService.js
import axios from 'axios';
import { getCookie } from '../Tool/CookieUtils';

// Mendapatkan token dari cookie
const token = getCookie("token");

const axiosConfig = {
  headers: {
    Authorization: `Bearer ${token}`, // Sertakan token JWT di header
  },
};

// Fungsi untuk fetch tiket berdasarkan tanggal
export const fetchTicketsByDate = async (date, page = 1, limit = 10) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_TICKETS}?date=${date}&page=${page}&limit=${limit}`, axiosConfig);
    return response.data;
  } catch (error) {
    throw error;
  } finally {
    console.log(`[INFO] Finished processing fetchTicketsByDate for date: ${date}`);
  }
};

// Fungsi untuk mengambil data tickets
export const fetchTickets = async (page = 1, limit = 10) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_TICKETS}?page=${page}&limit=${limit}`, axiosConfig);
    return {
      tickets: response.data.tickets,
      totalTickets: response.data.totalTickets,
      currentPage: response.data.currentPage,
      totalPages: response.data.totalPages,
    };
  } catch (error) {
    throw new Error('Error fetching tickets: ' + error);
  }
};

// Fungsi untuk mengambil data jobdesk
export const fetchJobdeskOptions = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_JOBDESK}`);
    return response.data.jobdesks;
  } catch (error) {
    throw new Error('Error fetching jobdesk: ' + error);
  }
};

// Fungsi untuk membuat tiket baru
export const createTicket = async (formData) => {
  try {
    await axios.post(`${process.env.REACT_APP_URL_TICKETS}`, formData, axiosConfig);
    console.log(formData);
  } catch (error) {
    throw new Error('Error creating ticket: ' + error);
  }
};

// Fungsi untuk memperbarui tiket
export const updateTicket = async (ticketId, formData) => {
  try {
    await axios.put(`${process.env.REACT_APP_URL_TICKETS}/${ticketId}`, formData, axiosConfig);
  } catch (error) {
    throw new Error('Error updating ticket: ' + error);
  }
};

// Fungsi untuk menghapus tiket
export const deleteTicket = async (ticketId) => {
  try {
    await axios.delete(`${process.env.REACT_APP_URL_TICKETS}/${ticketId}`, axiosConfig);
  } catch (error) {
    throw new Error('Error deleting ticket: ' + error);
  }
};

// Fungsi untuk mencari tiket berdasarkan nomor tiket
export const fetchTicketsByNomorTiket = async (nomorTiket) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_TICKET_SEARCH}?nomorTiket=${nomorTiket}`, axiosConfig);
    return response.data;
  } catch (error) {
    throw new Error('Error fetching ticket by nomor tiket: ' + error);
  }
};
