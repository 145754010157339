import { useEffect, useState } from 'react';
import { fetchTicketDataPerMonth } from '../../service/ChartTicketService'; // Import fungsi dari file yang dibuat
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

const TicketChart = () => {
  const [chartData, setChartData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchChartData = async () => {
      try {
        const ticketData = await fetchTicketDataPerMonth();

        const months = ticketData.map(item => `${item.month}/${item.year}`);
        const ticketCounts = ticketData.map(item => item.totalTickets);

        setChartData({
          labels: months,
          datasets: [
            {
              label: 'Tiket perbulan',
              data: ticketCounts,
              backgroundColor: 'rgba(75, 192, 192, 0.2)',
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 1,
            },
          ],
        });
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching ticket data per month:", error);
      }
    };

    fetchChartData();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="chart-container px-5 py-5">
      {isLoading ? (
        <p>Loading chart...</p>
      ) : (
        <Bar
          data={chartData}
          width={600}
          height={400}
          options={{
            responsive: true,
            plugins: {
              legend: {
                position: 'top',
              },
              title: {
                display: true,
                text: 'Tiket perbulan',
              },
            },
            scales: {
              y: {
                beginAtZero: true,
              },
            },
          }}
        />
      )}
    </div>
  );
};

export default TicketChart;
