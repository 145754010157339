import React, { useState, useEffect,useRef  } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Masonry from "react-masonry-css";
import { AnimatePresence } from "framer-motion";
// import TooltipWrapper from "./UI/TooltipWrapper";
import { getCookie } from "../Tool/CookieUtils";
import { jwtDecode } from "jwt-decode";
import HistoryModal from "../components/Template/PopUpHistoryTemplate";
import { useNotification } from "../Store/NotificationContext";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
// import Loading from '../components/UI/Loading'
import LoadingSkeletonCards from "./UI/LoadingSkeletonCards";
import { myProfile } from "../service/UsersService";
import useDocumentTitle from "../hooks/useDocumentTitle";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

function App() {
  const editorRef = useRef(null);
  const [templates, setTemplates] = useState([]);
  // eslint-disable-next-line
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOption, setSortOption] = useState("date_desc"); // Set default sort option to "date_desc"
  // const [copySuccess, setCopySuccess] = useState("");
  const [isAnyData, setData] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState(null); // State untuk menyimpan template yang dipilih
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [templateTitle, setTemplatesTitle] = useState("");
  const [userName, setUserName] = useState(""); // State untuk menyimpan nama pengguna
  const [sortOptionGender, setSortOptionGender] = useState("Bapak/Ibu");
  const [initial, setInitial] = useState("");
  const { showNotification } = useNotification();
  const [showBackToTop, setShowBackToTop] = useState(false);
  const [resetInisial, setResetInisial] = useState(false);
  const [customInisial, setCustomInisital] = useState("");
  const [loading, setLoading] = useState(false);
  const token = getCookie("token");

  const baseUrl = process.env.REACT_APP_LOCAL_HOST;
  const urlTemplate = process.env.REACT_APP_URL_BASE_TEMPLATE;
  const URL = baseUrl + urlTemplate;

  useDocumentTitle("Home - Template");

  const TooltipWrapper = ({ content, children }) => (
    <Tippy content={content} placement="auto">
      {children}
    </Tippy>
  );

  useEffect(() => {
    if (editorRef.current) {
      const observer = new MutationObserver(mutations => {
        mutations.forEach(mutation => {
          if (mutation.type === 'childList') {
           
            // Lakukan sesuatu dengan perubahan ini
          }
        });
      });

      observer.observe(editorRef.current, {
        childList: true,
        subtree: true
      });

      return () => observer.disconnect(); // Membersihkan observer saat komponen unmount
    }
  }, []);

  useEffect(() => {
    if (token) {
      try {
        const decoded = jwtDecode(token);
        if (decoded.role === "superadmin") {
          setShowHistory(true);
        } else {
          setShowHistory(false);
        }
      } catch (error) {
        console.error("Invalid token specified:", error);
      }
    }
  }, [token]);

  useEffect(() => {
    const fetchInisialAgen = async () => {
      try {
        const inisial = await myProfile();
        setInitial(inisial.data.user.inisial);
      } catch (error) {
        console.error("Error fetching inisial:", error);
      }
    };

    if (resetInisial) {
      setInitial(customInisial);
    } else {
      fetchInisialAgen();
    }
  }, [resetInisial, customInisial]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(URL)
      .then((response) => {
        setTemplates(response.data);
        if (response.data.length === 0) {
          setLoading(false);
          setData(true);
        } else {
          setLoading(false);
          setData(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching templates:", error);
      });
  }, [URL]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 200) {
        // Ubah angka ini sesuai dengan jumlah scroll yang diinginkan
        setShowBackToTop(true);
      } else {
        setShowBackToTop(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleSortChange = (event) => {
    setSortOption(event.target.value);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const sortedTemplates = [...templates].sort((a, b) => {
    if (sortOption === "title_asc" || sortOption === "title_desc") {
      const titleA = a.title.toLowerCase();
      const titleB = b.title.toLowerCase();
      if (sortOption === "title_asc") {
        return titleA.localeCompare(titleB);
      } else {
        return titleB.localeCompare(titleA);
      }
    } else {
      const dateA = new Date(a.last_edit_time);
      const dateB = new Date(b.last_edit_time);
      return sortOption === "date_asc" ? dateA - dateB : dateB - dateA;
    }
  });

  const filteredTemplates = sortedTemplates.filter(
    (template) =>
      template.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      template.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
      template.tags.some((tag) =>
        tag.toLowerCase().includes(searchTerm.toLowerCase())
      ) // Check if any tag matches the search term
  );

  const breakpointColumnsObj = {
    default: 3,
    1100: 2,
    700: 1,
    500: 1,
  };

  // const stripHtmlTags = (htmlString) => {
  //   const tempElement = document.createElement("div");
  //   tempElement.innerHTML = htmlString;
  //   return tempElement.textContent || tempElement.innerText || "";
  // };

  const stripHtmlTagsWithFormat = (htmlText) => {
    // Buat elemen dummy untuk memasukkan HTML
    const div = document.createElement("div");
    div.innerHTML = htmlText;

    // Gantikan <br> dengan newline
    div.innerHTML = div.innerHTML.replace(/<br\s*\/?>/gi, "\n");

    // Gantikan <p> dengan newline di awal dan akhir
    div.innerHTML = div.innerHTML.replace(/<\/p>/gi, "\n");
    div.innerHTML = div.innerHTML.replace(/<p[^>]*>/gi, "");

    // Ambil teks dari elemen dummy
    return div.textContent || div.innerText || "";
  };

  const handleCopyToClipBoard = (htmlText) => () => {
    const plainText = stripHtmlTagsWithFormat(htmlText);
    navigator.clipboard.writeText(plainText).then(
      () => {
        showNotification("Texs tersalin", "success");
      },
      (err) => {
        showNotification("Gagal menyalin texs", "error");
        console.error("Gagal menyalin teks: ", err);
      }
    );
  };

  const handleOpenHistoryModal = (templateId, templateTitle) => {
    setSelectedTemplateId(templateId);
    setIsModalOpen(true);
    setTemplatesTitle(templateTitle);
  };

  const handleCloseModal = () => {
    setSelectedTemplateId(null);
    setIsModalOpen(false);
  };

  // Fungsi untuk mengatur gender dan nama dalam template
  const replaceGenderPlaceholder = (text) => {
    if (sortOptionGender === "Bapak")
      return text.replace(/Bapak\/Ibu/g, `Bapak ${userName}`);
    if (sortOptionGender === "Ibu")
      return text.replace(/Bapak\/Ibu/g, `Ibu ${userName}`);
    return text.replace(/Bapak\/Ibu/g, `Bapak/Ibu ${userName}`);
  };

  const handleNameChange = (event) => {
    const value = event.target.value;
    const textCapitalize = value
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    setUserName(textCapitalize);
  };

  const resetGenderNama = () => {
    setSortOptionGender("Bapak/Ibu");
    setUserName("");
    showNotification("Nama ter-reset", "success");
  };

  const handleInitial = (event) => {
    setCustomInisital(event.target.value.toUpperCase());
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Animasi smooth scroll
    });
  };

  return (
    <div className="container items-center mx-auto p-4" ref={editorRef}>
      <h1 className="text-3xl font-bold mb-4">Templates</h1>
      <div className="flex flex-col md:flex-row justify-between items-center w-full mb-4">
        <div className="flex flex-col md:flex-row w-full justify-between items-center mb-4">
          {/* form atas  */}

          <div className="relative flex flex-col md:flex-row items-start md:items-center space-y-3 md:space-y-0 md:space-x-3 min-w-full ">
            <input
              type="text"
              placeholder="Cari ..."
              value={searchTerm}
              onChange={handleSearch}
              className="px-4 py-2 border rounded-md focus:outline-none w-full focus:border-blue-500"
            />
            <select
              value={sortOption}
              onChange={handleSortChange}
              className="px-4 py-2 border rounded-md focus:outline-none w-full focus:border-blue-500"
            >
              <option value="date_desc">Urutkan dari terbaru</option>
              <option value="date_asc">Urutkan dari terlama</option>
              <option value="title_asc">Urutkan sesuai Abjad (A-Z)</option>
              <option value="title_desc">Urutkan sesuai Abjad (Z-A)</option>
            </select>
            <TooltipWrapper content="Tambah Template">
              <Link
                to={`/tambah`}
                className="mt-4 md:mt-0 bg-dark-secondary md:w-20 w-full
               text-white px-4 py-2 rounded-md hover:bg-dark-primary
                focus:outline-none flex items-center justify-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 mr-2"
                  viewBox="0 0 20 20"
                  fill="none"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                  />
                </svg>
              </Link>
            </TooltipWrapper>
          </div>
        </div>
      </div>
      {/* Form bawah */}
      <div className="flex flex-col md:flex-row justify-between items-start max-w-full py-2">
        <div className="relative w-full md:w-auto pt-4 md:pt-0">
          <input
            type="text"
            maxLength={4}
            placeholder="Inisial Agen"
            value={initial}
            disabled={!resetInisial}
            onChange={handleInitial}
            className="px-4 py-2 border rounded-md focus:outline-none focus:border-dark-primary w-full md:w-auto mr-4"
          />
          {!resetInisial ? (
            <TooltipWrapper content="Tulis Inisial">
              <svg
                onClick={() => setResetInisial(!resetInisial)}
                className="absolute top-9 sm:top-1/2 right-4 transform -translate-y-1/2 cursor-pointer"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m14.304 4.844 2.852 2.852M7 7H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-4.5m2.409-9.91a2.017 2.017 0 0 1 0 2.853l-6.844 6.844L8 14l.713-3.565 6.844-6.844a2.015 2.015 0 0 1 2.852 0Z"
                />
              </svg>
            </TooltipWrapper>
          ) : (
            <TooltipWrapper content="Inisial">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setResetInisial(!resetInisial)}
                viewBox="0 0 24 24"
                fill="currentColor"
                className="absolute top-9 sm:top-1/2 right-4 transform -translate-y-1/2 cursor-pointer"
                width="24"
                height="24"
              >
                <path
                  fillRule="evenodd"
                  d="M9.75 6.75h-3a3 3 0 0 0-3 3v7.5a3 3 0 0 0 3 3h7.5a3 3 0 0 0 3-3v-7.5a3 3 0 0 0-3-3h-3V1.5a.75.75 0 0 0-1.5 0v5.25Zm0 0h1.5v5.69l1.72-1.72a.75.75 0 1 1 1.06 1.06l-3 3a.75.75 0 0 1-1.06 0l-3-3a.75.75 0 1 1 1.06-1.06l1.72 1.72V6.75Z"
                  clipRule="evenodd"
                />
                <path d="M7.151 21.75a2.999 2.999 0 0 0 2.599 1.5h7.5a3 3 0 0 0 3-3v-7.5c0-1.11-.603-2.08-1.5-2.599v7.099a4.5 4.5 0 0 1-4.5 4.5H7.151Z" />
              </svg>
            </TooltipWrapper>
          )}
        </div>
        <div className="flex flex-col md:flex-row w-full md:w-auto items-end md:items-center space-y-3 md:space-y-0 md:space-x-2 mt-4 md:mt-0">
          <div className="w-full md:w-auto">
            <select
              value={sortOptionGender}
              onChange={(event) => setSortOptionGender(event.target.value)}
              className="px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500 w-full md:w-auto"
            >
              <option value="Bapak/Ibu">Bapak/Ibu</option>
              <option value="Bapak">Bapak</option>
              <option value="Ibu">Ibu</option>
            </select>
          </div>

          <div className="w-full md:w-auto">
            <input
              type="text"
              placeholder="Nama user"
              value={userName}
              onChange={handleNameChange}
              className="px-2 py-2 border w-full md:w-auto rounded-md focus:outline-none focus:border-dark-primary"
            />
          </div>

          <div className="w-full md:w-auto flex justify-end">
            <TooltipWrapper content="Reset Nama User">
              <button onClick={resetGenderNama}
              className="sm:bg-none w-full bg-dark-primary text-white rounded-md px-2 py-2 justify-center flex items-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 transition ease-in-out duration-300 delay-150 hover:rotate-90"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                  />
                </svg>
              </button>
            </TooltipWrapper>
          </div>
        </div>
      </div>

      {/* {loading && <Loading width={52} height={52}/>} */}
      {isAnyData && <p className="text-red-500">Template kosong</p>}
      {error && <p className="text-red-500">{error}</p>}
      <AnimatePresence>
        {loading ? (
          <LoadingSkeletonCards />
        ) : (
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {filteredTemplates.map((template) => (
              <div
                key={template._id}
                className="bg-white rounded-xl  hover:shadow-2xl shadow-lg relative"
              >
                <div className="p-4">
                  <div className="flex justify-between pb-4">
                    <div>
                      <h2 className="text-xl font-bold mb-2">
                        {template.title || <Skeleton />}
                      </h2>
                    </div>
                    <div className="pl-2">
                      <TooltipWrapper content="Copy">
                        <button
                          onClick={handleCopyToClipBoard(
                            `${template.description}\n${
                              initial ? `(${initial})` : ""
                            }`
                          )}
                          className=" font-bold h-full top-9 sticky text-gray-700 hover:text-black "
                        >
                          <svg
                            className="w-6 h-6 text-black-800  "
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M9 8v3a1 1 0 0 1-1 1H5m11 4h2a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1h-7a1 1 0 0 0-1 1v1m4 3v10a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1v-7.13a1 1 0 0 1 .24-.65L7.7 8.35A1 1 0 0 1 8.46 8H13a1 1 0 0 1 1 1Z"
                            />
                          </svg>
                        </button>
                      </TooltipWrapper>
                    </div>
                  </div>
                  <div className="text-gray-600 -mt-5">
                    <ReactQuill
                      value={`${replaceGenderPlaceholder(
                        template.description
                      )}\n${initial ? ` (${initial})` : ""}`}
                      readOnly={true}
                      theme={"bubble"}
                    />
                  </div>
                </div>
                <div className="bg-gray-100  px-1 py-2 flex justify-between divide-x-2 items-center">
                  <p className="text-xs text-gray-500  max-w-44">
                    Last edited by {template.last_editor} on{" "}
                    {template.last_edit_time
                      ? new Date(template.last_edit_time).toLocaleString()
                      : "Unknown"}
                  </p>
                  <div className="z-10 absolute md:relative bottom-0 right-0 divide-y-2 flex flex-row justify-center items-center space-x-4 p-2">
                    <div className="flex items-center  space-x-2">
                      {/* Icon untuk menampilkan history template */}
                      {showHistory ? (
                        <TooltipWrapper content="History Template">
                          <button
                            onClick={() =>
                              handleOpenHistoryModal(
                                template._id,
                                template.title
                              )
                            }
                            className="text-dark-secondary font-semibold flex items-center space-x-2"
                          >
                            {/* Sesuaikan 'to' sesuai dengan rute yang ingin Anda tuju */}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                              />
                            </svg>
                          </button>
                        </TooltipWrapper>
                      ) : (
                        <div></div>
                      )}

                      <TooltipWrapper content="Edit">
                        <Link
                          to={`/edit/${template._id}`}
                          className="text-dark-secondary font-semibold flex items-center space-x-2"
                        >
                          <svg
                            className="w-6 h-6 text-gray-700 hover:text-black"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="m14.304 4.844 2.852 2.852M7 7H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-4.5m2.409-9.91a2.017 2.017 0 0 1 0 2.853l-6.844 6.844L8 14l.713-3.565 6.844-6.844a2.015 2.015 0 0 1 2.852 0Z"
                            />
                          </svg>
                        </Link>
                      </TooltipWrapper>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Masonry>
        )}
      </AnimatePresence>

      {/* Modal History */}
      <HistoryModal
        templateId={selectedTemplateId}
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        titleTemplate={templateTitle}
      />

      {/* notifikasi  */}
      {showBackToTop && (
        <button
          onClick={scrollToTop}
          className="fixed bottom-4 right-4 bg-dark-primary text-white px-4 py-2 rounded hover:bg-blue-950"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m4.5 15.75 7.5-7.5 7.5 7.5"
            />
          </svg>
        </button>
      )}
      {/* <AnimatePresence>
        {copySuccess && (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.5 }}
            className={`absolute inset-x-0 top-0 flex items-center justify-center w-50 h-20 opacity-75 bg-dark-info m-auto z-10`}
          >
            <p className="text-white">{copySuccess} </p>
          </motion.div>
        )}
      </AnimatePresence> */}
    </div>
  );
}

export default App;
