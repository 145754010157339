// src/services/ticketService.js
import axios from 'axios';
import { getCookie } from '../Tool/CookieUtils';

// Mendapatkan token dari cookie
const token = getCookie("token");

// Konfigurasi axios dengan token JWT
const axiosConfig = {
  headers: {
    Authorization: `Bearer ${token}`,
  },
};

// Fungsi untuk fetch tiket per bulan
export const fetchTicketDataPerMonth = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_TICKETS_MONTHLY}`, axiosConfig);
    return response.data;
  } catch (error) {
    throw error;
  } finally {
    console.log('[INFO] Finished processing fetchTicketDataPerMonth');
  }
};
